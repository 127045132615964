import React from 'react'
import { css } from '@emotion/core'
import theme from '../theme'
import Logo from '../Logo'
import LogoText from '../LogoText'

export default props => (
  <div
    css={css`
      background: ${theme.color.dark};
      display: grid;
      align-items: center;
      grid-auto-flow: column;
      justify-content: space-between;
      padding: 2.5rem 1.2rem 5rem 1.2rem;
      height: 40px;
      color: #818184;
      ${theme.font.tiny};
    `}
  >
    <a href="/">
      <div css={css`
        height: 60px;
        display: grid;
        grid-template-columns: 60px 150px;
        grid-column-gap: 10px;
        align-items: center;
      `}>
        <Logo />
        <LogoText />
      </div>
    </a>

    <a href="https://twitter.com/geddski">
      <div
        css={css`
          background-image: url(/img/logos/twitter-blue.png);
          background-repeat: no-repeat;
          background-size: contain;
          height: 30px;
          width: 30px;

          &:hover {
            -webkit-filter: grayscale(0%) brightness(100%) contrast(100%);
            filter: grayscale(0%) brightness(100%) contrast(100%);
          }

          -webkit-filter: grayscale(100%) brightness(135%) contrast(120%);
          filter: grayscale(100%) brightness(135%) contrast(120%);
        `}
      />
    </a>
    
    <span>
      <a href="/privacy">Privacy Policy</a> &nbsp;
      <span>© 2016-2022 Mastery Games</span>
    </span>
  </div>
)
