/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { TextButton } from "shared/components/TextButton";
import { logEvent } from "../util/analytics";

interface MiniSplashProps {
  gameID: string;
  subtitle: string;
}

const navToGame = (gameID: string) => {
  logEvent("nav: click main menu game", {game: gameID});
  window.location.href = `/${gameID}`;
}

const GameMiniSplash = (props: MiniSplashProps) => {
  const charCount = props.gameID.split("").length;

  return <div onClick={() => { navToGame(props.gameID) }} css={theme => css`
    margin-bottom: 45px;
    cursor: pointer;
    // box-shadow: 0 0 10px 0 red;
    &:hover{
      // outline: 1px solid ${theme.color.primary.light};
      box-shadow: 0 0 0 10px ${theme.color.primary.highlight}; 
      background-color: ${theme.color.primary.highlight};
    }
  `}>
    <img css={css`margin-bottom: 5px;`} src={`/shared/img/mini-splash/${props.gameID}.jpg`}></img>
    <div css={css`
      background-image: url(/shared/icons/${props.gameID}.svg);
      background-repeat: no-repeat;
      background-size: contain;
      font-size: 23px;
      margin-bottom: 5px;
      width: ${charCount}ch;
      text-shadow: 6px 6px 10px black;
      height: 25px;
    `}></div>
    {/* <div>enrolled status or price</div> */}
    <div css={css`
      font-size: 15px;
    `}>{props.subtitle}</div>
  </div>
}

const MainMenu = () => {
  return <div css={css`font-size: 16px;`}>
    <TextButton to="/">Blog</TextButton>
    <TextButton to="/newsletter">Newsletter</TextButton>
    <TextButton to="/contact">Contact</TextButton>
    <br/>
    <br/>
    <GameMiniSplash gameID="gridcritters" subtitle="Rescue the critters, master CSS Grid" />
    <GameMiniSplash gameID="flexboxzombies" subtitle="Slay the zombies, master CSS Flexbox" />
    <GameMiniSplash gameID="serviceworkies" subtitle="Fight the beasts, master Service Workers" />
  </div>
}

export default MainMenu;
