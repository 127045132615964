import React from 'react'
import { css } from '@emotion/core'

/**
 * processes paragraphs to remove paragraph wrapping from images.
 */
export default props => {
  // const windowSize = window.scrollWidth
  // console.log(windowSize)

  if (
    props.children &&
    props.children.props &&
    props.children.props.originalType === 'img'
  ) {
    return (
      <div
        css={css`
          margin: 1em 1.2rem;
          max-width: 100ch;
          grid-column: 1 / 4 !important;
          justify-self: center;
        `}
      >
        {props.children}
      </div>
    )
  }

  return <p {...props} />
}
