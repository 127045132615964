// important note: <Code></Code> children has to have an empty line before the code starts, and pass a string when used directly.
// for example see state-machines-in-react.mdx

/**
 <Code
  theme="light"
  lang="js"
  content={
`export const Menu = ({ setStatus }) => {
  const element = useRef();
  const [current, send] = useMachine(menuMachine);
};`}
/>
 */

import React from 'react'
import { stripIndent } from 'common-tags'

let ace = null
if (typeof window !== `undefined`) {
  ace = require('brace')
  require('brace/mode/css')
  require('brace/mode/html')
  require('brace/mode/javascript')
  require('brace/mode/sh')
  require('brace/theme/monokai')
  require('brace/theme/xcode')
  var Range = ace.acequire('ace/range').Range
}

export default class Code extends React.Component {
  state = {
    // seems like \n in code only works as attributes, not children :(
    contents: this.props.content
      ? // ? stripIndent`${this.props.content}`
        `${this.props.content}`
      : this.props.children,
  }

  componentDidMount() {
    // this.fixBasicCodeBlocks()
    this.fixMobileHeight()
    this.configureEditor()
    if (this.props.highlight) {
      this.highlightLines()
    }
  }

  fixMobileHeight() {
    const height = this.pre.getBoundingClientRect().height
    // console.log('height', height)
    this.pre.style.height = `${height}px`
  }

  // lang can come either from props (from CodeExample) or be set on the pre component directly (via markdown/mdx)
  getLang() {
    let lang = null
    if (this.props.lang) {
      if (this.props.lang === 'js') {
        // want "js" to work cause we all know I'm gonna type that
        lang = 'javascript'
      } else {
        lang = this.props.lang
      }
      return `ace/mode/${lang}`
    }

    try {
      const markdownLangProp = this.props.children.props.className

      if (markdownLangProp === 'language-js') {
        lang = 'javascript'
      } else if (markdownLangProp === 'language-html') {
        lang = 'html'
      } else if (markdownLangProp === 'language-css') {
        lang = 'css'
      } else if (markdownLangProp === 'language-sh') {
        lang = 'sh'
      }

      return `ace/mode/${lang}`
    } catch (e) {
      // console.warn('CODE LANG')
    }
  }

  // get rid of the inner code tag if there is one because it adds an extra line to regular code blocks.
  // this is breaking some code examples like the first one in dynamic svg
  fixBasicCodeBlocks() {
    const codeElem = this.pre.querySelector('code')
    if (codeElem) {
      this.pre.innerHTML = stripIndent`${codeElem.innerText}`
    }
  }

  removeEmptyBottomLine() {
    // there's an extra ace_line (inside of ace_content) at the bottom of regular code snippets for some reason...
  }

  configureEditor() {
    let lang = this.getLang()

    this.editor = ace.edit(this.pre)
    // hide cursor no matter what
    this.editor.renderer.$cursorLayer.element.style.opacity = 0

    // show cursor once clicked
    if (this.props.live) {
      this.editor.on('focus', e => {
        this.editor.renderer.$cursorLayer.element.style.opacity = 1
      })

      this.editor.on('blur', () => {
        this.editor.renderer.$cursorLayer.element.style.opacity = 0
      })
    }

    if (this.props.theme === 'dark') {
      this.editor.setTheme('ace/theme/monokai')
      this.editor.container.style['background-color'] = '#1d1b21'
      // console.log('this.editor', this.editor)
    } else {
      this.editor.setTheme('ace/theme/xcode')
      this.editor.container.style['background-color'] = '#f7f7f7'
    }

    this.editor.getSession().setMode(lang)

    this.editor.renderer.setPadding(20)
    this.editor.renderer.setScrollMargin(10, 30)

    this.editor.setReadOnly(!this.props.live)
    this.editor.setHighlightActiveLine(false)
    this.editor.renderer.setShowGutter(false)
    this.editor.setShowPrintMargin(false)
    this.editor.setOptions({
      maxLines: 1000,
      autoScrollEditorIntoView: true,
      fontFamily: 'Fira Code',
    })
    this.editor.setBehavioursEnabled(false)

    this.editor.resize(true)

    this.editor.session.on('change', () => {
      this.props.onChange && this.props.onChange(this.editor.getValue())
    })
  }

  highlightLines() {
    this.props.highlight.forEach(highlight => {
      // startRow, startColumn, endRow, endColumn
      // -1 because it appears to be off by one. see state-machines-in-react.mdx
      const range = new Range(highlight.from - 1, 0, highlight.to - 1, 999)
      this.editor.session.addMarker(range, 'highlightline', 'fullLine')
    })
  }

  render() {
    // prettier-ignore
    // return <pre ref={el => { this.pre = el }} >{this.props.children}</pre>
    return <pre ref={el => { this.pre = el }} >{this.state.contents}</pre>
  }
}

Code.defaultProps = {
  theme: 'dark',
}
