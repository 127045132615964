import React from 'react'

const getRandomInt = function(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export default props => {
  const randomGem = getRandomInt(1, 4)

  return (
    <div style={{ marginLeft: '70px', marginBottom: '10px', position: 'relative' }}>
      <img
        alt=""
        style={{
          width: '30px',
          display: 'inline-block',
          position: 'absolute',
          left: '-50px',
          marginRight: '20px'
        }}
        src={`/img/gem${randomGem}.png`}
      />
      <span className="bullet">{props.children}</span>
    </div>
  )
}
