import React from 'react'

export default class YouTube extends React.Component {
  render() {
    return (
      <div
        style={{
          position: 'relative',
          paddingBottom: '56.25%',
          paddingTop: '30px',
          height: 0,
          overflow: 'hidden',
          marginBottom: '30px',
        }}
      >
        <iframe
          title={`${this.props.title}`}
          src={`https://www.youtube.com/embed/${this.props.id}?start=${
            this.props.start
          }&end=${this.props.end}&rel=0&showinfo=0&modestbranding=0`}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 'none',
          }}
          wmode="transparent"
          allowFullScreen={true}
          frameBorder="0"
        />
      </div>
    )
  }
}

YouTube.defaultProps = {
  start: 0,
  end: 0,
  title: '(untitled youtube video)',
}
