import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import Layout from '../components/layout'
import Promo from '../components/promo'
import Code from '../components/code'
import Banner from '../components/partials/banner'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Paragraph from '../components/Paragraph'
import H2 from "../components/H2";
import H3 from "../components/H3";

const ThreeColumn = `
  display: grid;
  grid-template-columns: minmax(1.2rem, 1fr) minmax(auto, 57ch) minmax(
      1.2rem,
      1fr
    );
`

const components = {
  pre: Code,
  p: Paragraph,
  h2: H2,
  h3: H3
}

export default props => {
  const post = props.pageContext.frontmatter || {}
  let image = `/img/${props.pageContext.name}.jpg`;
  if (post.pic){
    image = post.pic;
  }
  const noPic = post.noPic;

  /* everything but the image wrapper div (Paragraph.js) and live code example goes in the middle */
  const Post = styled.div`
    color: #2e2e32;
    padding-top: 2rem;
    /* prettier-ignore */
    ${ThreeColumn}

    > * {
      grid-column: 2;
    }

    h2 {
      margin-top: 2rem;
    }
  `

  return (
    <MDXProvider components={components}>
      <Layout
        title={post.title}
        description={post.description}
        image={`https://mastery.games/img/${props.pageContext.name}.jpg`}
      >
        <Banner />
        <Post>
          {noPic ? null : (
            <img
              src={image}
              css={css`
                grid-column: 1 / 4 !important;
                justify-self: center;
                max-width: calc(100% - 1.2rem);
                margin: 1.2rem;
                margin-bottom: 2.5rem;

                @media (min-width: 1220px) {
                  max-width: 110ch;
                  max-height: 65vh;
                }
              `}
              alt=""
            />
          )}
          <h1>{post.title}</h1>
          {props.children}
        </Post>
        <div
          css={css`
            ${ThreeColumn};
          `}
        >
          {post.promo ? <Promo promo={post.promo} /> : null}
        </div>
      </Layout>
    </MDXProvider>
  )
}