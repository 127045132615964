import { injectGlobal } from 'emotion';

export const setSharedFontFaces = () => {
  injectGlobal`
    /* code text */
    @font-face {
      font-family: 'Fira Code';
      src:  url('/shared/fonts/FiraCode-VF.ttf');
      font-weight: 500;
      font-style: normal;
    }

    /* regular body text */
    @font-face {
      font-family: 'Inter';
      src:  url('/shared/fonts/Inter-VariableFont.ttf');
      font-weight: 400;
      font-style: normal;
    }
  `;
}
