import React from 'react'

// a little image in the content, accepts a size prop. e.g.
// <LittleImg width="200px" src="/img/moom.png" />
export default props => {
  const attrs = {
    alt: "",
    ...props
  }
  return <img {...attrs} style={{justifySelf: "center", margin: "1em 1.2rem"}}></img>
}
