import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Button from '../../components/Button'

const ContainerClass = css`
  opacity: 0;
  transition: opacity 1s linear;

  &.shown {
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: #0f0f15;
    overflow: hidden;
    color: white;
  }
`

const Content = styled.div`
  width: 1400px;
  display: grid;
  grid-template-columns: 4fr 5fr;
  justify-items: center;
`

const Message = styled.div`
  font-size: 0.9rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 50px;
`

const Title = () => {
  const imgStyle = css`
    height: auto;
    width: 550px;
  `
  return <img css={imgStyle} alt="" src={`/fbz_challenge/fbz_title.png`} />
}

const Hoodie = () => {
  const imgStyle = css`
    height: auto;
    width: 500px;
    margin: 0 50px;
  `
  return <img css={imgStyle} alt="" src="/fbz_challenge/hoody.png" />
}

const CloseButton = ({ onClose }) => {
  const closeStyle = css`
    color: white;
    position: absolute;
    right: 15px;
    top: 5px;
    cursor: pointer;
    font-size: 40px;
  `

  return (
    <div css={closeStyle} onClick={onClose}>
      &times;
    </div>
  )
}

export default ({ success = true, onClose = () => {}, nextChallenge }) => {
  return (
    <div css={ContainerClass} className={`${success ? 'shown' : ''}`}>
      {success ? (
        <React.Fragment>
          <CloseButton onClose={onClose} />
          <Content>
            <Hoodie />
            <Message>
              <div
                css={css`
                  font-weight: bold;
                  font-size: 30px;
                `}
              >
                You did it!
              </div>

              {nextChallenge ? (
                <div
                  css={css`
                    margin-bottom: 30px;
                  `}
                >
                  <Button to={`/flexbox/challenge/${nextChallenge}/`}>
                    Next Challenge
                  </Button>
                </div>
              ) : null}

              <div>More challenges await you in...</div>
              <Title />
              <div>
                Flexbox is incredibly powerful. But it's also crazy hard to get
                the hang of. Master it once and for all, in a way that actually
                sticks, so you can build any layout you can imagine with
                flexbox!
              </div>
              <Button to="https://flexboxzombies.com">
                Play Flexbox Zombies
              </Button>
            </Message>
          </Content>
        </React.Fragment>
      ) : null}
    </div>
  )
}
