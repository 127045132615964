/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import React, {Component} from "react";
import styled from '@emotion/styled';
import { gsap } from "gsap";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import Mousetrap from "mousetrap";
import CloseButton from "./CloseButton";
import { pSBC } from "../util/colors";

gsap.registerPlugin(ScrollToPlugin);

const paneStyle = (isOpen) => theme => css`
  transform: translateX(-100%);
  position: absolute;
  background: ${theme.color.primary.dark};
  top: 0;
  bottom: 0;
  left: -20px;
  padding: 24px;
  width: 354px;
  border-left: 20px solid ${theme.color.primary.dark};
  box-shadow: ${isOpen ? `0 0 34px 18px ${pSBC(-0.4, theme.color.primary.dark)}` : "none"};
  overflow: auto;
`;

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 334px;
  width: 100vw;
  height: 100vh;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const Controls = styled.div`
  position: absolute;
  left: 24px;
`;

const Title = styled.div`
  text-transform: uppercase;
  font-size: 16px;
`;

export default class Pane extends Component {
  state = {
    open: this.props.component ? true : false
  };

  componentWillUnmount() {
    Mousetrap.unbind("escape");
  }

  componentDidUpdate(prevProps, prevState) {
    const isOpening = !prevProps.component && this.props.component;
    const isClosing = prevProps.component && !this.props.component;
    const hasLevelChanged = this.props.level !== prevProps.level;
    // handles scrolling when level changes without clicks
    if (hasLevelChanged){
      this.scrollTo();
    }

    if (isOpening) {
      this.setState(state => {
        return {
          open: true
        };
      });
      this.show();
    } else if (isClosing) {
      this.setState(state => {
        return {
          open: false
        };
      });
      this.hide();
    }
  }

  show() {
    gsap.to(this.el, {
      duration: 0.35,
      ease: "elastic.out(1,1)",
      x: "0%",
      onComplete: () => {
        Mousetrap.bind("escape", this.props.onClose);
        this.scrollTo();
      }
    });
  }

  scrollTo() {
    if (this.props.scrollTo === "currentLevel" && this.props.level){
      const slug = this.props.level.slug;
      if (slug === "/"){
        // game's home page, don't scroll
      }
      else {
        gsap.to(this.el, {duration: .4, scrollTo:{y:`#${slug}`, offsetY:25}, ease: "power2.out"});
      }
    }
  }

  hide = () => {
    gsap.to(this.el, {
      duration: 0.35,
      x: "-100%",
      ease: "power2.inOut",
      onComplete: () => {
        this.props.onClose && this.props.onClose();
      }
    });
  };

  toggleOpen = () => {
    this.setState(state => {
      return {
        open: !state.open
      };
    });
  };

  render() {
    const ContentComponent = this.props.component;
    return (
      <React.Fragment>
        <div css={paneStyle(this.state.open)} ref={el => (this.el = el)}>
          <Header>
            <Controls>
              <CloseButton onClose={this.props.onClose} />
            </Controls>
            <Title>{this.props.title}</Title>
          </Header>
          {ContentComponent ? <ContentComponent onClose={this.props.onClose} level={this.props.level}/> : null }
        </div>
        {this.state.open ? <Overlay onClick={this.props.onClose} /> : null}
      </React.Fragment>
    );
  }
}
