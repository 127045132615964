/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// fix gatsby not scrolling element into view for URLs with hashes
window.scrollToHash = function(id) {
  try {
    var el = document.querySelector(id)
    el && el.scrollIntoView();
  }
  catch (e){
    // console.log('e', e);
  }
}

export const onRouteUpdate = (thing) => {
  const location = thing.location;
  if (location && location.hash !== "") {
    setTimeout(() => {
      window.scrollToHash(location.hash);
    }, 10)
  }
}