import React from 'react'

export default props => {
  return (
    <>
      <div className="heartquote">{props.children}</div>
      <div className="quoteby">
        <span>—{props.by}</span>
      </div>
    </>
  )
}
