import React from 'react'
import Helmet from 'react-helmet'

const Head = props => {
  return (
    <Helmet title={props.title}>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=0"
      />
      <link rel="icon" href="/favicon.ico?v=2.3" />
      <meta name="description" content={props.description} />

      {/* Twitter Cards */}
      <meta property="og:image" content={props.image || "https://mastery.games/img/mastery-through-play2.jpg"} />
      <meta property="og:image:type" content="image/jpg" />
      <meta property="og:image:width" content="2600" />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:url" content="https://mastery.games" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@geddski" />
      <meta name="twitter:creator" content="@geddski" />
    </Helmet>
  )
}

// Head.defaultProps = {
//   description:
// }

export default Head
