import React from 'react'

export default class CanIUse extends React.Component {
  componentDidMount() {
    // MIGTODO: fix this
    // import(`../lib/caniuse-embed`)
  }

  render() {
    return (
      <p
        className="ciu_embed"
        data-feature={this.props.feature}
        data-periods="future_1,current,past_1"
      >
        <a href="https://caniuse.com/#feat=once-event-listener">Can I Use?</a>{' '}
        Data on support for the this feature across the major browsers from
        caniuse.com.
      </p>
    )
  }
}
