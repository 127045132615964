/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import React from "react";

interface Props {
  enabled?: Boolean;
  to?: string;
  processing?: Boolean;
  children?: any;
  onClick?: Function;
}

/**
 * 
 */
export const TextButton = (props: Props) => (
  <button css={theme => css`
    display: inline-block;
    border: none;
    padding: 14px 10px;
    outline: none;
    color: #fff;
    color: ${theme.color.primary.light};
    background: none;
    font-family: Inter, sans-serif;
    font-variation-settings: 'wght' 450;
    font-size: inherit;
    cursor: ${props.enabled !== false ? "pointer" : "default" };
    border-radius: 5px;

    &:hover {
      background: ${props.enabled !== false ? theme.color.primary.highlight : "none"};
      color: ${props.enabled !== false ? "#ffffff" : theme.color.primary.light };
    }

    &:active {
      // background: ${theme.color.primary.dark};
      color: #fff;
    }
  `} onClick={(e) => {
    e.preventDefault();
    if (props.enabled !== false){
      props.onClick && props.onClick();
      if (props.to){
        window.location.href = props.to;
      }
    }
  }}>{props.children}</button>
)
