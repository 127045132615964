import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Head from './partials/head'
import Footer from './partials/footer'
import { ThemeProvider } from 'emotion-theming'
import theme from './theme'
// eslint-disable-next-line
import normalise from './normalize.css'
// eslint-disable-next-line
import '../lib/normalize.css'
import { setSharedFontFaces } from "shared/components/fontFaces";
import '../global.css'

setSharedFontFaces();

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={staticData => {
      return (
        <div>
          <ThemeProvider theme={theme}>
            <div className="site">
              <Head
                title={`${props.title || staticData.site.siteMetadata.title}`}
                description={props.description}
                image={props.image}
              />

              {/* content */}
              {props.children}

              <Footer />
            </div>
          </ThemeProvider>
        </div>
      )
    }}
  />
)
