import React from 'react'
import FlexboxChallenge from './FlexboxChallenge'

export default class extends React.Component {
  render() {
    const literalStyles = `
      .no-cheating, .flexbox-challenge {
        height: 500px;
      }

      .no-cheating > *, .flexbox-challenge > *{
        margin: 10px;
        width: 100px;
        height: 200px;
      }

      .no-cheating {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .no-cheating > *:nth-of-type(1) {
        flex: 0 0 25%;
        align-self: flex-start;
      }

      .no-cheating > *:nth-of-type(5) {
        flex: 1 0 0;
        align-self: flex-end;
      }

      .flexbox-challenge {
        display: flex;
        align-items: RRRRRMMMMMP;
        justify-content: GAAAAAGGGK;
      }

      .flexbox-challenge > *:nth-of-type(1) {
        flex: GRRRRAAACK;
        align-self: SSMMMERRRG;
      }
      
      .flexbox-challenge > *:nth-of-type(5) {
        flex: GRRRRUUUCK;
        align-self: ZZZZKKKKRRRR;
      }
    `

    return (
      <FlexboxChallenge
        title="Flexbox Devtools Challenge"
        description="Fix the Flexbox layout using just the devtools!"
        literalStyles={literalStyles}
        infected={[1, 5]}
      >
        <div />
        <div />
        <div />
        <div />
        <div />
      </FlexboxChallenge>
    )
  }
}
