const theme = {
  color: {
    primary: {
      dark: '#232327' ,
      // highlight: "#46464c"
      highlight: "#3f384c"
    },
    secondary: {
      // mid: "#3d3652"
      // mid: "rgb(136, 125, 246)"
      mid: "#90319b" // same color as links
    },
    accent: {
      // mid: "rgb(136, 125, 246)"
      mid: "#483768",
      // dark: "#3d3652"
    },
    purple: '#90319b',
    pink: '#d13f73',
    black: '#0f0f14',
    dark: '#232327', 
  },
  font: {
    tiny: `
      font-size: 0.65rem;
    `,
    small: `
      font-size: 0.75rem;
    `,
    medium: `
      font-size: 0.95rem;
    `,
  },
  arrange: {
    gridcenter: `
      display: grid;
      justify-items: center;
    `,
  },
}

export default theme
