import React, { useState } from "react";
import styled from '@emotion/styled';
import Tip from "./Tip";
// import { playSound } from "shared/util/sound";

const Close = styled.a`
  color: #fff;
  border: none;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  cursor: pointer;
`;

const Icon = styled.div`
  ${props => {
    if (props.tip === "left") {
      return "order: 1";
    }
  }};
`;

const CloseButton = props => {
  const [showTip, setShowTip] = useState(false);
  const hideTheTip = () => {
    setShowTip(false);
  };
  const showTheTip = () => {
    setShowTip(true);
  };

  const playAdvanceSound = () => {
    // playSound("button/SFX_advance");
  };

  return (
    <Close
      onClick={props.onClose}
      onMouseEnter={showTheTip}
      onMouseLeave={hideTheTip}
      onMouseDown={playAdvanceSound}
    >
      <Icon {...props}>
        <img alt="close" src={`/shared/icons/close.svg`} />
      </Icon>
      <Tip show={showTip} color="#d0cfd1">
        (escape)
      </Tip>
    </Close>
  );
};

export default CloseButton;
