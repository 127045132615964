import React from 'react'
import FlexboxChallenge from './FlexboxChallenge'

export default class extends React.Component {
  render() {
    const literalStyles = `
      .no-cheating, .flexbox-challenge {
        height: 500px;
      }

      .no-cheating > *, .flexbox-challenge > *{
        margin: 10px;
        height: 75%;
      }

      .no-cheating {
        display: flex;
        align-items: center;
      }

      .no-cheating > *:nth-of-type(1) {
        flex-basis: 50%;
      }

      .no-cheating > *:nth-of-type(3) {
        flex-basis: 50%;
      }

      .flexbox-challenge > *:nth-of-type(1) {
        flex-basis: ZZZURRRMMMMG;
      }
      
      .flexbox-challenge > *:nth-of-type(3) {
        flex-basis: BFRRRRFFFF;
      }

      .flexbox-challenge {
        display: flex;
        align-items: GRRRAAAARG;
      }
    `

    return (
      <FlexboxChallenge
        title="Flexbox Devtools Challenge"
        description="Fix the Flexbox layout using just the devtools!"
        literalStyles={literalStyles}
        nextChallenge={8}
        infected={[1, 3]}
      >
        <div />
        <div />
        <div />
      </FlexboxChallenge>
    )
  }
}
