import BlogPost from "../../../../src/templates/blog-post";
import { graphql } from 'gatsby';
import CodeExample from "../../../../src/components/CodeExample";
import Code from "../../../../src/components/code";
import Caption from "../../../../src/components/Caption";
import LittleImg from "../../../../src/components/LittleImg";
import * as React from 'react';
export default {
  BlogPost,
  graphql,
  CodeExample,
  Code,
  Caption,
  LittleImg,
  React
};