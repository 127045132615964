import React from 'react'
import FlexboxChallenge from './FlexboxChallenge'

export default class extends React.Component {
  render() {
    const literalStyles = `
      .no-cheating, .flexbox-challenge {
        height: 400px;
      }

      .no-cheating > *, .flexbox-challenge > *{
        margin: 10px;
        width: 200px;
        height: 50%;
      }

      .no-cheating {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .flexbox-challenge {
        display: flex;
        justify-content: BRAINNNSSSS;
        align-items: GRRRRRRRRNN;
      }
    `

    return (
      <FlexboxChallenge
        title="Flexbox Devtools Challenge"
        description="Fix the Flexbox layout using just the devtools!"
        literalStyles={literalStyles}
        nextChallenge={4}
      >
        <div />
        <div />
        <div />
        <div />
      </FlexboxChallenge>
    )
  }
}
