import React from 'react'
import FlexboxChallenge from './FlexboxChallenge'

export default class extends React.Component {
  render() {
    const literalStyles = `
      .no-cheating, .flexbox-challenge {
        height: 500px;
      }

      .no-cheating > *, .flexbox-challenge > *{
        margin: 10px;
        height: 200px;
        width: 50%;
      }

      .no-cheating {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .no-cheating > *:nth-of-type(1) {
        flex-shrink: 1;
      }

      .no-cheating > *:nth-of-type(2) {
        flex-shrink: 1;
      }

      .no-cheating > *:nth-of-type(3) {
        flex-shrink: 0;
      }
      

      .flexbox-challenge {
        display: flex;
        flex-direction: GGAAARRRHGGG;
        align-items: MMMMRRRRRG;
      }

      .flexbox-challenge > *:nth-of-type(1) {
        flex-shrink: HHHHHGGGGGAAAAG;
      }
      
      .flexbox-challenge > *:nth-of-type(2) {
        flex-shrink: BLARRRG;
      }
      
      .flexbox-challenge > *:nth-of-type(3) {
        flex-shrink: BBRRUUUGGGSSS;
      }
      
    `

    return (
      <FlexboxChallenge
        title="Flexbox Devtools Challenge"
        description="Fix the Flexbox layout using just the devtools!"
        literalStyles={literalStyles}
        nextChallenge={7}
        infected={[1, 2, 3]}
      >
        <div />
        <div />
        <div />
      </FlexboxChallenge>
    )
  }
}
