import React from 'react'
import FlexboxChallenge from './FlexboxChallenge'

export default class extends React.Component {
  render() {
    const literalStyles = `
      .no-cheating, .flexbox-challenge {
        height: 500px;
      }

      .no-cheating > *, .flexbox-challenge > *{
        margin: 10px;
        width: 200px;
      }

      .no-cheating {
        display: flex;
      }

      .no-cheating > *:nth-of-type(2) {
        flex-grow: 1;
        height: 75%;
        align-self: center;
      }

      .flexbox-challenge {
        display: flex;
      }

      .flexbox-challenge > *:nth-of-type(2) {
        height: 75%;
        align-self: SSSSPPPRRRRRS;
        flex-grow: FGGGGGRERRRFF;
      }
      
    `

    return (
      <FlexboxChallenge
        title="Flexbox Devtools Challenge"
        description="Fix the Flexbox layout using just the devtools!"
        literalStyles={literalStyles}
        nextChallenge={6}
        infected={[2]}
      >
        <div />
        <div />
        <div />
      </FlexboxChallenge>
    )
  }
}
