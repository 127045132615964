import React from 'react'
import { css } from '@emotion/core'
import Button from './Button'

const promos = {
  grids: {
    pic: '/gc/grid-critters-footer.jpg',
    alt: 'Grid Critters Game',
    link: 'https://gridcritters.com',
    description: `Master CSS Grid right from the start by playing this new mastery
    game. You'll learn the ins and outs of Grids one fun level at a time,
    while saving an adorable alien life form from certain destruction.`,
    cta: 'Master CSS Grid',
  },
  flexbox: {
    pic: '/fbz/flexboxzombiespic.jpg',
    alt: 'Flexbox Zombies Game',
    link: 'https://flexboxzombies.com',
    description: `Flexbox is incredibly powerful. But it's also crazy hard to master. So
    we all end up depending on a cheat sheet and guessing in the dev
    tools. Enough of that! Time to master it once and for all, in a way
    that actually sticks, so you can build any layout you can imagine with
    flexbox.`,
    cta: 'Master Flexbox',
  },
  serviceworkies: {
    pic: '/sw/serviceworkies.png',
    alt: 'Service Worker Game',
    link: 'https://serviceworkies.com',
    description: `PWAs are the future of web apps. At their very heart is the powerful but devious Service Worker. Learn to control it inside and out, and make your web app compete with the very best of native.`,
    cta: 'Master Service Workers',
  },
}

const Promo = props => {
  const item = promos[props.promo || 'flexbox']

  return (
    <>
      <a
        href={item.link}
        css={css`
          grid-column: 1 / 4;
          margin: 1.2rem;
          margin-bottom: 0;
          max-width: 100ch;
          justify-self: center;
        `}
      >
        <img src={item.pic} alt={item.alt} />
      </a>
      <p
        css={css`
          grid-column: 2;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 2.4rem 0;
        `}
      >
        {item.description}
        <Button to={item.link}>{item.cta}</Button>
      </p>
    </>
  )
}

export default Promo
