import React from 'react'

export default class UserStyles extends React.Component {
  state = {
    hasRendered: false,
  }

  componentDidMount() {
    this.setState(
      {
        hasRendered: true,
      },
      () => {
        this.props.onRendered && this.props.onRendered()
      }
    )
  }

  render() {
    return (
      <React.Fragment>
        <style>{this.props.styles}</style>
        {this.state.hasRendered ? this.props.children : null}
      </React.Fragment>
    )
  }
}
