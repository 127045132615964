import React, { useState } from 'react'
import { css } from '@emotion/core'
import Logo from '../Logo'
import LogoText from '../LogoText'
import theme from '../theme'
import Pane from "shared/components/Pane";
import MainMenu from "shared/components/MainMenu";


export default props => {
  const [isOpen, setOpen] = useState(false);
  
  const openMenu = () => {
    setOpen(true);
  }

  const closeMenu = () => {
    setOpen(false);
  }

  return (
  <div
    css={css`
      background: ${theme.color.dark};
      color: #ffffff;
      height: 80px;
    `}
  >
    <div css={css`
      position: fixed;
      top: 0;
      bottom: 0;
    `}>
      <Pane title="Mastery Games" onClose={closeMenu} component={isOpen ? MainMenu : null} />
    </div>

    <span onClick={openMenu} css={theme => css`
      display: inline-grid;
      padding-left: 10px;
      padding-right: 10px;
      grid-column-gap: 10px;
      height: 80px;
      grid-template-columns: 60px 150px;
      align-items: center;
      align-content: center;
      cursor: pointer;
      &:hover {
        background-color: ${theme.color.accent.mid};
      }
    `}>
      <Logo />
      <LogoText />
    </span>
    {/* moving newsletter into the menu */}
    {/* <div
      css={css`
        ${theme.font.small};
      `}
    >
      <a css={css`
        color: #fff;
        text-decoration: none;
      `} href="/newsletter">newsletter</a>
    </div> */}
  </div>
)}
