import BlogPost from "../../../../src/templates/blog-post";
import { graphql } from 'gatsby';
import CodeExample from "../../../../src/components/CodeExample";
import YouTube from "../../../../src/components/YouTube";
import Action from "../../../../src/components/Action";
import LittleImg from "../../../../src/components/LittleImg";
import Video from "../../../../src/components/Video";
import * as React from 'react';
export default {
  BlogPost,
  graphql,
  CodeExample,
  YouTube,
  Action,
  LittleImg,
  Video,
  React
};