import React from 'react'
import { stripIndent } from 'common-tags'
import Code from './code'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const Pencil = styled.div`
  background-image: url(/img/pencil.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  height: 23px;
  width: 23px;
  position: absolute;
  top: 1rem;
  right: 1rem;

  @media (min-width: 1220px) {
    /* right: calc(50% + 1rem); */
  }
`

export default class CodeExample extends React.Component {
  componentDidMount() {
    // firefox requires this but chrome/safari ignore it
    this.iframe.onload = () => {
      this.initializeExample()
    }
    this.initializeExample()
  }

  render() {
    let lang = null
    let content = null
    if (this.props.showHTML) {
      lang = 'html'
      content = this.props.showHTML
      this.type = 'html'
    } else if (this.props.showJS) {
      lang = 'js'
      content = this.props.showJS
      this.type = 'js'
    } else if (this.props.showCSS) {
      lang = 'css'
      content = this.props.showCSS
      this.type = 'css'
    }

    return (
      <div
        css={css`
          display: grid;
          border: 1px solid #e5e5e5;
          border-radius: 1px;
          grid-column: 1 / 4 !important;
          position: relative;
          justify-self: center;
          width: 100%;
          max-width: calc(100% - 2.4rem);
          margin: 1.2rem;

          @media (min-width: 1220px) {
            max-width: 100ch;
            /* grid-template-columns: 1fr 1fr; */
          }

          pre {
            margin-top: 0;
            margin-bottom: 0;
          }
        `}
      >
        <Code
          live={this.props.live}
          lang={lang}
          theme="dark"
          onChange={this.onCodeChange.bind(this)}
        >{stripIndent`${content}`}</Code>

        <Pencil />

        <div
          // no scrollbar or touch for vertical, all examples take up as much height as they need.
          // touch scroll for width only
          css={css`
            overflow-y: hidden;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            /* display: grid;
            align-content: center; */
          `}
        >
          <iframe
            ref={iframe => {
              this.iframe = iframe
            }}
            title="geddski code example"
            width="100%"
            style={{
              outline: 'none',
              border: 'none',
              padding: 0,
              margin: 0,
            }}
          />
        </div>
      </div>
    )
  }

  updateCSS(newCode) {
    const css = this.iframe.contentDocument.body.querySelector('#update-css')
    css.innerHTML = newCode
    // update height of frame based on content
    // const body = this.iframe.contentDocument.body
    // this.setFrameHeight(body);
  }

  updateHTML(newCode) {}

  updateJS(newCode) {
    // reload the thing but with the new JS code
    this.iframe.onload = () => {
      this.initializeExample({ js: newCode })
    }
    this.iframe.contentWindow.location.reload(true)
  }

  onCodeChange(newCode) {
    if (this.type === 'css') {
      this.updateCSS(newCode)
    } else if (this.type === 'html') {
      this.updateHTML(newCode)
    } else if (this.type === 'js') {
      this.updateJS(newCode)
    }

    // keeps the thing sized correctly as its content changes around
    this.setFrameHeight(this.iframe.contentDocument.body)
  }

  loadTheme(body, callback) {
    // add the theme css if any
    if (this.props.theme) {
      import(`./codethemes/${this.props.theme}`).then(theme => {
        const themeCSS = document.createElement('style')
        themeCSS.innerHTML = theme.default
        body.append(themeCSS)
        callback && callback()
      })
    } else {
      callback && callback()
    }
  }

  loadFixedCSS(body) {
    // add the fixed css from the example
    const fixedCSS = document.createElement('style')
    fixedCSS.innerHTML = this.props.fixedCSS
    body.append(fixedCSS)
  }

  loadFixedJS(body) {
    // add the fixed JS from the example (BEFORE the updateable JS)
    const fixedJS = document.createElement('script')
    fixedJS.innerHTML = this.props.js
    body.append(fixedJS)
  }

  initializeCSS(body) {
    // add the updatable css
    const css = document.createElement('style')
    css.setAttribute('id', 'update-css')
    // add what's in "showCSS" so I don't have to duplicate it
    css.innerHTML = this.props.showCSS
    body.append(css)
  }

  initializeJS(body, content = this.props.showJS) {
    // add the updatable JS
    // gives time for the css to render first
    setTimeout(() => {
      const js = document.createElement('script')
      js.setAttribute('id', 'update-js')
      js.innerHTML = content
      body.append(js)
    }, 0)
  }

  initializeHTML(body) {
    body.innerHTML = this.props.html
  }

  initializeExample(options = {}) {
    const body = this.iframe.contentDocument.body
    this.initializeHTML(body)

    this.loadTheme(body, () => {
      this.loadFixedCSS(body)
      this.initializeCSS(body)
      this.loadFixedJS(body)
      if (options.js) {
        this.initializeJS(body, options.js)
      } else {
        this.initializeJS(body)
      }
      this.setFrameHeight(body)
    })
  }

  setFrameHeight(body) {
    // set the height based on content
    this.iframe.height = body.scrollHeight
  }
}
