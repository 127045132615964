import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

export default props => (
  <div css={css`
    width: 100%;
    height: 100%;
  `}>
    <svg width="100%" height="100%" viewBox="0 0 163 163" fill="none">
      <path d="M39.8112 63.6343L60.4997 42.9458C61.8163 41.6292 62.4747 40.9708 62.7213 40.2117C62.9383 39.5439 62.9383 38.8246 62.7213 38.1569C62.4747 37.3978 61.8163 36.7394 60.4997 35.4228L57.0516 31.9747C55.7349 30.658 55.0766 29.9997 54.3174 29.753C53.6497 29.5361 52.9304 29.5361 52.2626 29.753C51.5035 29.9997 50.8452 30.658 49.5285 31.9747L17.8689 63.6343C16.5522 64.951 15.8939 65.6093 15.6472 66.3685C15.4302 67.0362 15.4302 67.7555 15.6472 68.4233C15.8939 69.1824 16.5522 69.8407 17.8689 71.1574L49.5285 102.817C50.8452 104.134 51.5035 104.792 52.2626 105.039C52.9304 105.256 53.6497 105.256 54.3174 105.039C55.0766 104.792 55.7349 104.134 57.0516 102.817L60.4997 99.369C61.8163 98.0523 62.4747 97.394 62.7213 96.6348C62.9383 95.9671 62.9383 95.2478 62.7213 94.58C62.4747 93.8209 61.8163 93.1625 60.4997 91.8459L39.8112 71.1574C38.4945 69.8407 37.8362 69.1824 37.5895 68.4233C37.3726 67.7555 37.3726 67.0362 37.5895 66.3685C37.8362 65.6093 38.4945 64.951 39.8112 63.6343Z" fill="white"/>
      <path d="M102.504 35.4225L105.952 31.9744C107.269 30.6577 107.927 29.9994 108.686 29.7527C109.354 29.5358 110.073 29.5358 110.741 29.7527C111.5 29.9994 112.158 30.6577 113.475 31.9744L145.135 63.634C146.451 64.9507 147.11 65.609 147.356 66.3681C147.573 67.0359 147.573 67.7552 147.356 68.4229C147.11 69.1821 146.451 69.8404 145.135 71.1571L113.475 102.817C112.158 104.133 111.5 104.792 110.741 105.038C110.073 105.255 109.354 105.255 108.686 105.038C107.927 104.792 107.268 104.133 105.952 102.817L102.504 99.3687C101.187 98.052 100.529 97.3937 100.282 96.6345C100.065 95.9668 100.065 95.2475 100.282 94.5797C100.529 93.8206 101.187 93.1622 102.504 91.8456L123.192 71.1571C124.509 69.8404 125.167 69.1821 125.414 68.423C125.631 67.7552 125.631 67.0359 125.414 66.3681C125.167 65.609 124.509 64.9507 123.192 63.634L102.504 42.9455C101.187 41.6289 100.529 40.9705 100.282 40.2114C100.065 39.5436 100.065 38.8243 100.282 38.1566C100.529 37.3974 101.187 36.7391 102.504 35.4225Z" fill="white"/>
      <path d="M74.6052 105.952L71.1571 102.504C69.8405 101.187 69.1821 100.529 68.423 100.282C67.7552 100.065 67.0359 100.065 66.3682 100.282C65.6091 100.529 64.9507 101.187 63.6341 102.504L57.0517 109.086C55.7349 110.403 55.0765 111.061 54.3173 111.308C53.6495 111.525 52.9302 111.525 52.2624 111.308C51.5033 111.061 50.8449 110.403 49.5283 109.086L14.7364 74.288C13.4198 72.9712 12.7614 72.3127 12.0023 72.066C11.3345 71.849 10.6151 71.849 9.94734 72.0659C9.18816 72.3126 8.52978 72.971 7.21301 74.2877L3.76448 77.7363C2.44788 79.0529 1.78958 79.7112 1.54292 80.4703C1.32595 81.138 1.32593 81.8573 1.54286 82.525C1.78949 83.2841 2.44775 83.9425 3.76428 85.2591L49.5283 131.028C50.845 132.345 51.5033 133.003 52.2624 133.25C52.9302 133.467 53.6495 133.467 54.3173 133.25C55.0765 133.004 55.7349 132.345 57.0516 131.028L74.6052 113.475C75.9219 112.158 76.5802 111.5 76.8269 110.741C77.0439 110.073 77.0439 109.354 76.8269 108.686C76.5802 107.927 75.9219 107.268 74.6052 105.952Z" fill="#BCB9C5"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M79.1479 30.564L44.6671 65.0448C43.3687 66.3432 43.3687 68.4483 44.6671 69.7468L107.359 132.439C108.658 133.737 110.763 133.737 112.061 132.439L160.647 83.8485C161.945 82.55 161.945 80.445 160.647 79.1467L154.377 72.8769C153.078 71.5785 150.973 71.5785 149.675 72.8771L112.061 110.496C110.763 111.795 108.658 111.795 107.359 110.497L94.821 97.9583C93.5226 96.6599 93.5226 94.5548 94.821 93.2564L118.331 69.7467C119.629 68.4483 119.629 66.3432 118.331 65.0448L83.8498 30.564C82.5514 29.2656 80.4463 29.2656 79.1479 30.564ZM86.9808 58.7803C82.22 63.5412 82.22 71.2599 86.9808 76.0207C91.7416 80.7815 99.4604 80.7815 104.221 76.0207C108.982 71.2599 108.982 63.5411 104.221 58.7803C99.4604 54.0195 91.7416 54.0195 86.9808 58.7803Z" fill="#BCB9C5"/>
      <path d="M101.086 72.8856C98.0566 75.9152 93.1446 75.9152 90.115 72.8856C87.0854 69.856 87.0854 64.9441 90.115 61.9145C93.1446 58.8849 98.0566 58.8849 101.086 61.9145C104.116 64.9441 104.116 69.856 101.086 72.8856Z" fill="white"/>
      </svg>
</div>
)
