import React from "react";
import styled from '@emotion/styled';

const Container = styled.div`
  color: ${props => {
    return props.theme.color.primary.light || "#ffffff";
  }};
  font-size: 14px;
  transition-property: opacity;
  transition-duration: 0.8s;
  transition-delay: ${props => {
    return props.show ? "1s" : "0s";
  }};
  opacity: ${props => {
    return props.show ? 1 : 0;
  }};
`;

const Tip = props => {
  return (
    <Container {...props}>
      <div>{props.children}</div>
    </Container>
  );
};

export default Tip;
