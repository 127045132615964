import React from 'react'
import { css } from '@emotion/core'

const gameColors = {
  serviceworkies: '#52c6b3',
}

const iconPositionTweaks = {
  polish: '55%',
  features: '40%',
  gameplay: '45%',
}

const containerStyle = css`
  display: grid;
  grid-template-columns: 70px 1fr;
  grid-column-gap: 1rem;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 0.6rem;

  h2 {
    margin: 0;
  }
`

const PatchHeader = props => {
  const color = gameColors[props.game]
  const vertical = iconPositionTweaks[props.type] || '50%'

  const iconStyle = css`
    background-image: url(${`/icons/patch/${props.type}.svg`});
    background-repeat: no-repeat;
    background-size: 55%;
    background-position: 50% ${vertical};
    width: 70px;
    height: 70px;
    border-radius: 35px;
    background-color: ${color};
  `
  return (
    <div css={containerStyle}>
      <div css={iconStyle} />
      <h2>{props.children}</h2>
    </div>
  )
}

export default PatchHeader
