import amplitude from "amplitude-js";
import { Game } from "../definitions";

export type Event = 
  | "play: complete level" // DONE eventProperties: gameID, chapter, level
  | "play: complete fbz" // DONE
  | "play: complete sw" // DONE
  | "play: complete gc" // DONE
  // | "play: complete chapter"
  // mastery games account registration
  | "registration: fill form" 
  | "registration: create account"
  // email
  | "email: click fbz welcome pitch" // DONE
  | "email: click sw welcome pitch"
  // TODO: need a way to handle these:
  // | "email: receive support"
  // | "email: click support pitch" // include game property in all these?
  // blog
  | "blog: visit post"
  | "blog: click mention pitch" // a game mentioned in a blog post
  | "blog: click promo pitch" // the promo at the bottom of blog posts
  // sales external landing pages
  | "nav: visit fbz landing page" // DONE
  | "nav: visit gc landing page" // DONE
  | "nav: visit sw landing page" // DONE
  // in-platform enrollment pages
  | "enrollment: visit fbz enrollment page" // DONE
  | "enrollment: visit sw enrollment page" // DONE
  | "enrollment: visit gc enrollment page" // DONE
  | "enrollment: enroll in fbz" // DONE
  | "enrollment: enroll in sw" // DONE
  | "enrollment: enroll in gc" // DONE
  | "enrollment: enter payment info" // DONE eventProperties: {game: gameID}
  | "enrollment: purchase gc" // DONE
  // | "enrollment: purchase gc success"
  // | "enrollment: purchase gc fail"
  // other nav in the games/platform
  | "nav: click main menu game" // DONE eventProperties: {game: gameID}
  | "nav: click game end pitch" // DONE eventProperties: {game: gameID}
  | "nav: click chapter end pitch" // DONE eventProperties: {game: gameID, chapter: "11", currentGame: gameName }
  // currentGame is the game they're playing, game is the name of the game they clicked on

export const logEvent = (event: Event, eventProperties?: any, callback?: any) => {
  amplitude.getInstance().logEvent(event, eventProperties, callback);
}

export const logEnroll = (gameID: string) => {
  if (gameID === "flexboxzombies"){
    logEvent("enrollment: enroll in fbz");
  }
  else if (gameID === "gridcritters"){
    logEvent("enrollment: enroll in gc");
  }
  else if (gameID === "serviceworkies"){
    logEvent("enrollment: enroll in sw");
  }
}

// log a visit to the enrollment page if the user isn't already enrolled
export const logEnrollmentVisit = (gameID: string, game: Game) => {
  if (gameID === "flexboxzombies" && !game.enrollments.includes(gameID)){
    logEvent("enrollment: visit fbz enrollment page");
  }
  else if (gameID === "gridcritters" && !game.enrollments.includes(gameID)){
    logEvent("enrollment: visit gc enrollment page");
  }
  else if (gameID === "serviceworkies" && !game.enrollments.includes(gameID)){
    logEvent("enrollment: visit sw enrollment page");
  }
}

export const logGamePurchase = (gameID: string) => {
  if (gameID === "gridcritters"){
    logEvent("enrollment: purchase gc");
  }
}