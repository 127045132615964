import React from 'react'
import { css } from '@emotion/core'

// for image captions
const Caption = (props) => {
  return <div css={css`
    color: #cccccc;
    font-size: 0.55rem;
    justify-self: center;
  `}>{props.children}</div>
}

export default Caption;