import React from 'react'
import { css } from '@emotion/core'
// React handles most attributes smartly, excluding them if false.
// But if I ever need non-muted video, I'll have to either see if 
// https://github.com/facebook/react/pull/20087 has been merged,
// or https://github.com/facebook/react/issues/10389 solve some other way,
// or do some other workaround. 

export default props => {
  let options = {
    playsInline: true,
    muted: true,
    loop: true,
    autoPlay: true,
    ...props
  }

  // using maxWidth on the container instead
  delete options.maxWidth;
  
  let position = 'grid-column: 1 / 4 !important;';
  if (props.compact){
    position = 'grid-column: 2 / 3 !important;'
  }
  
  return (
    <div
      css={() => css`
        margin: 1em 1.2rem;
        max-width: 100ch;
        ${position}
        justify-self: center;
        max-width: ${props.maxWidth || "100%"};
      `}
    >
      <video {...options} muted />
    </div>
  )
}
