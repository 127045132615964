import React from 'react'
import { css } from '@emotion/core'
import Button from '../components/Button'
import { SolidButton } from "shared/components/SolidButton"

export default props => {
  return (
    <div
      css={css`
        display: grid;
        justify-content: center;
        justify-items: center;
        margin-bottom: 3rem;
      `}
    >
      <SolidButton enabled={true} to={props.link}>{props.children}</SolidButton>
    </div>
  )
}
