import React from 'react'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import SuccessOverlay from './SuccessOverlay'
import InfoHeader from './InfoHeader'
import ZombieFooter from './ZombieFooter'
import UserStyles from './UserStyles'
import Layout from '../../components/layout'
import Banner from '../../components/partials/banner'
import { gsap } from 'gsap'

const Container = styled.div`
  min-height: 600px;
  min-width: 500px;
  position: relative;
  padding: 3rem;
  z-index: 1;

  @keyframes shadow {
    0% {
      box-shadow: -5px 5px 0px rgba(252, 62, 133, 0.8),
        5px -5px 0px rgba(255, 38, 93, 1);
    }

    20% {
      opacity: 1;
      box-shadow: -5px 5px 0px rgba(252, 62, 133, 0.8),
        5px -5px 0px rgba(255, 38, 93, 1);
    }

    25% {
    }

    30% {
      box-shadow: -15px 15px 0px rgba(252, 62, 133, 0.8),
        15px -15px 0px rgba(255, 38, 93, 1);
    }

    40% {
      box-shadow: -5px 5px 0px rgba(252, 62, 133, 0.8),
        5px -5px 0px rgba(255, 38, 93, 1);
    }

    50% {
      box-shadow: -15px 15px 0px rgba(252, 62, 133, 0.8),
        15px -15px 0px rgba(255, 38, 93, 1);
    }

    75% {
      box-shadow: -5px 5px 0px rgba(252, 62, 133, 0.8),
        5px -5px 0px rgba(255, 38, 93, 1);
    }

    83% {
      box-shadow: -15px 15px 0px rgba(252, 62, 133, 0.8),
        15px -15px 0px rgba(255, 38, 93, 1);
    }

    100% {
      box-shadow: -5px 5px 0px rgba(252, 62, 133, 0.8),
        5px -5px 0px rgba(255, 38, 93, 1);
    }
  }

  .no-cheating > *,
  .flexbox-challenge > * {
    padding: 20px;
  }
`

const correctContainer = css`
  width: 90%;
  user-select: none;
  pointer-events: none;
  position: absolute;
  z-index: 2;
  /* border: 2px solid #0f0f14; */
  border: 2px solid #add9fe;

  .no-cheating > * {
    user-select: none;
    pointer-events: none;
    /* border: 2px solid #fff;
    border: 2px solid #6ec7ff; */
    border: 2px solid transparent;
    outline: 5px solid #add9fe;
  }
`

const userContainer = css`
  opacity: 0;
  width: 90%;
  position: absolute;
  z-index: 1;
  border: 2px solid #0f0f14;

  .flexbox-challenge > * {
    border: 2px solid #65439a;

    background-image: url(/fbz_challenge/border-n.png),
      url(/fbz_challenge/border-w.png), url(/fbz_challenge/border-e.png),
      url(/fbz_challenge/splatter1.png), url(/fbz_challenge/splatter2.png),
      url(/fbz_challenge/skulls.jpg);
    background-repeat: repeat-x, repeat-y, repeat-y, no-repeat, no-repeat,
      no-repeat;
    background-size: auto 50px, 50px auto, 50px auto, cover, contain, cover;
    background-position-x: 50%, 0%, 100%, 50%, 100%, 50%;
    background-position-y: 0%, 50%, 50%, 100%, 0%, 50%;

    box-shadow: -5px 5px 0px rgba(252, 62, 133, 0.8),
      5px -5px 0px rgba(255, 38, 93, 1);

    animation-duration: 1.5s;
    animation-delay: 2s;
    animation-name: shadow;
    animation-iteration-count: 2;

    &:hover {
    }
  }
`

const ZombieEffects = [
  'Blarrrrrrgh',
  'Arrrrrrg',
  'Braaaaaains',
  'Zommmmmbeeees',
  'Flexbox_Yummmmy',
  'Eat_Dommmmm',
]
const ZombieEffectString = ZombieEffects.map(
  classname => `
    .${classname} {
      opacity: 1;
      transition:
        background-position-x 1s linear,
        background-position-y ${getRandomInt(1, 7)}s linear;
        background-position-x: 50%, 0%, 100%, ${getRandomInt(0, 100)}%,
          ${getRandomInt(0, 100)}%, ${getRandomInt(0, 100)}% !important;
        background-position-y: 0%, 50%, 50%, ${getRandomInt(0, 100)}%,
          ${getRandomInt(0, 100)}%, ${getRandomInt(0, 100)}% !important;
    }
  `
).join('\n\n')

const ZombieEffectClasses = css`
  ${ZombieEffectString}
`

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

// Get 2 random items from an array, but not the same items
function getRandomItem(array) {
  console.assert(array.length >= 1)
  return array[getRandomInt(0, array.length - 1)]
}

class FlexboxChallenge extends React.Component {
  constructor(...props) {
    super(...props)
    this.finished = false
    this.state = {
      success: false,
    }
  }

  doCorruptionAnimation = () => {
    this.userContainer.style.opacity = 1
    this.getCorrespondingElements().forEach(({ correct, user }) => {
      gsap.from(user, {
        duration: 2,
        opacity: 0,
        delay: 1,
      })

      // give zombie skulls a random background position
      let correctBounds = correct.getBoundingClientRect()
      let userBounds = user.getBoundingClientRect()
      let dx = correctBounds.x - userBounds.x
      let dy = correctBounds.y - userBounds.y

      this.animateInfectionLoop(user)

      user.style['position'] = 'relative'
      requestAnimationFrame(() => {
        gsap.from(user, {
          duration: 2,
          top: dy,
          left: dx,
          width: correctBounds.width,
          height: correctBounds.height,

          //config
          delay: 2,
          onComplete: () => {
            // clear the element styles so they don't clutter the user's view
            user.setAttribute('style', '')
          },
        })
      })
    })
  }

  replaceDefaultCSSValues(value, property) {
    const propertyDefaults = {
      'align-self': 'stretch',
      'align-items': 'stretch',
      'justify-content': 'flex-start',
      'align-content': 'stretch',
      'flex-wrap': 'nowrap',
      'flex-flow': 'row nowrap',
      'flex-grow': 0,
      'flex-shrink': 1,
      'flex-basis': 'auto',
      flex: '0 1 auto',
    }

    if (value === 'normal') {
      return propertyDefaults[property]
    }
    return value
  }

  getCorrespondingElements() {
    const correctElems = document.querySelectorAll('.no-cheating > *')
    const userElems = document.querySelectorAll('.flexbox-challenge > *')

    console.assert(userElems.length === correctElems.length)
    return Array.from(correctElems).map((correct, i) => ({
      correct,
      user: userElems[i],
    }))
  }

  checkAnswer() {
    //TODO: will need to handle flex shorthands. Maybe pass that as an option from the exercise
    const parentProperties = [
      'display',
      'flex-direction',
      'justify-content',
      'align-items',
      'flex-wrap',
      'align-content',
    ]
    const childProperties = [
      'flex-grow',
      'flex-shrink',
      'align-self',
      'order',
      'flex-basis',
      'min-width',
      'max-width',
      'min-height',
      'max-height',
    ]

    const correctParent = document.querySelector('.no-cheating')
    const userParent = document.querySelector('.flexbox-challenge')

    let check = this.getCorrespondingElements().map(
      ({ user, correct }, index) => ({
        index,
        name: 'target',
        propertiesToCheck: childProperties,
        user: window.getComputedStyle(user),
        correct: window.getComputedStyle(correct),
      })
    )

    check.push({
      name: 'parent',
      propertiesToCheck: parentProperties,
      user: window.getComputedStyle(userParent),
      correct: window.getComputedStyle(correctParent),
    })

    const errors = check
      .map(pair => {
        return pair.propertiesToCheck
          .map(property => {
            const correct = this.replaceDefaultCSSValues(
              pair.correct.getPropertyValue(property),
              property
            )
            const user = this.replaceDefaultCSSValues(
              pair.user.getPropertyValue(property),
              property
            )

            if (user === correct) {
              return { passes: true }
            } else if (
              this.props.ignoreJustifyContent &&
              property === 'justify-content'
            ) {
              return { passes: true }
            } else {
              return {
                passes: false,
                name: pair.name,
                nth: pair.index + 1,
                property: property,
                user: user,
                correct: correct,
              }
            }
          })
          .filter(check => !check.passes)
      })
      .reduce((prev, curr) => {
        return prev.concat(curr)
      })

    // align-items is ok if it's the only error.
    // This makes it so people can use align-items and align-self however they want.
    if (
      errors.length === 1 &&
      errors[0].name === 'crossbow' &&
      errors[0].property === 'align-items' &&
      !this.props.checkOnAlignOnly
    ) {
      // remove the error
      errors.splice(0, 1)
    }

    return errors
  }

  check() {
    let errors = this.checkAnswer()
    if (errors.length === 0) {
      this.finished = true
      this.successSequence()
    }
  }

  beginCheckLoop() {
    this.finished = false
    setTimeout(() => {
      if (this.finished) {
        return
      }
      this.check()
      this.beginCheckLoop()
    }, 3000)
  }

  animateInfectionLoop(elem) {
    let current_class = null
    const animate = () => {
      if (current_class) {
        elem.classList.remove(current_class)
      }

      current_class = getRandomItem(ZombieEffects)
      elem.classList.add(current_class)
      setTimeout(animate, getRandomInt(1, 7) * 1000)
    }
    animate()
  }

  successSequence() {
    Promise.all(
      this.getCorrespondingElements().map(({ user }) => {
        return new Promise(onComplete => {
          gsap.to(user, {
            duration: 3,
            opacity: 0,
            onComplete
          })
        })
      })
    ).then(() => {
      console.log('nailed it!')
      // hide the container so that devtools hide
      this.userContainer.style.display = 'none'
      this.zombieFooter.die().then(() => {
        this.setState({ success: true })
      })
    })
  }

  componentWillUnmount() {
    this.finished = true
  }

  componentDidMount() {
    this.beginCheckLoop()
  }

  resetLevel = () => {
    window.location.reload()
  }

  render() {
    let infected = []
    this.props.children.forEach((child, index) => {
      const addClass = this.props.infected.includes(index + 1) ? 'infected' : ''
      infected.push(<div key={`div-${index}`} className={addClass} />)
    })

    return (
      <React.Fragment>
        <Global styles={ZombieEffectClasses} />
        <SuccessOverlay
          success={this.state.success}
          onClose={this.resetLevel}
          nextChallenge={this.props.nextChallenge}
        />
        <Layout title={this.props.title} description={this.props.description}>
          <Banner />
          <InfoHeader />
          <Container>
            <UserStyles
              styles={this.props.literalStyles}
              onRendered={this.doCorruptionAnimation}
            >
              <div css={correctContainer}>
                <div className="no-cheating">{this.props.children}</div>
              </div>
              <div css={userContainer} ref={el => (this.userContainer = el)}>
                <div className="flexbox-challenge infected">{infected}</div>
              </div>
            </UserStyles>
          </Container>
          <ZombieFooter
            ref={dom => {
              this.zombieFooter = dom
            }}
          />
        </Layout>
      </React.Fragment>
    )
  }
}

FlexboxChallenge.defaultProps = {
  infected: [],
}

export default FlexboxChallenge
