import React from 'react'
import FlexboxChallenge from './FlexboxChallenge'

export default class extends React.Component {
  render() {
    const literalStyles = `
      .no-cheating, .flexbox-challenge {
        height: 400px;
      }

      .no-cheating > *, .flexbox-challenge > *{
        margin: 10px;
        width: 200px;
      }

      .no-cheating {
        display: flex;
        flex-direction: row-reverse;
      }

      .flexbox-challenge {
        display: flex;
        flex-direction: BLARRGGGG;
      }
    `

    return (
      <FlexboxChallenge
        title="Flexbox Devtools Challenge"
        description="Fix the Flexbox layout using just the devtools!"
        literalStyles={literalStyles}
        nextChallenge={2}
      >
        <div />
        <div />
        <div />
        <div />
      </FlexboxChallenge>
    )
  }
}
