import React from 'react'
import { css } from '@emotion/core'

const Note = (props) => {
  return <div css={css`
    color: #00a4c3;
    font-size: 0.8rem;
  `}>{props.children}</div>
}

export default Note;