import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { gsap } from 'gsap'

const Outer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Container = styled.div`
  position: relative;
  background: #201f27;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  width: 100%;
  overflow: hidden;
  justify-content: flex-end;
`

const FirefoxLogo = styled.div`
  background-image: url('/fbz_challenge/FirefoxLogo.png');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100px;
  min-width: 100px;
  height: 37.5px;
  min-height: 37.5px;
  &:hover {
    cursor: ${props => {
      if (props.usePointer) {
        return 'pointer'
      }
      return 'auto'
    }};
  }
`

const CloseButton = styled.div`
  font-size: 50px;
  position: absolute;
  right: 20px;
  top: -10px;
  cursor: pointer;
  user-select: none;
  opacity: 1;
`

const Icon = styled.img`
  margin: 0 0.5ch;
  position: relative;
  top: 7px;
`

export default class extends React.Component {
  state = {
    isOpen: true,
  }

  close = () => {
    if (!this.state.isOpen) {
      return
    }

    this.setState(
      state => {
        return {
          isOpen: false,
        }
      },
      () => {
        const anim = gsap.timeline()
        anim.add(gsap.to(this.closeButton, { duration: 0.15, opacity: 0 }))
        anim.add(
          gsap.to(this.container, { duration: 0.35, height: '75', ease: 'EaseOut' })
        )
        anim.add(
          gsap.to(this.container, { duration: 0.35, width: '130', ease: 'EaseOut' })
        )
      }
    )
  }

  open = () => {
    if (this.state.isOpen) {
      return
    }

    this.setState(
      state => {
        return {
          isOpen: true,
        }
      },
      () => {
        const anim = gsap.timeline()
        anim.add(
          gsap.to(this.container, { duration: 0.35, width: '100%', ease: 'EaseOut' })
        )
        anim.add(
          gsap.to(this.container, {
            duration: 0.35,
            height: this.closeHeight,
            ease: 'EaseOut',
          })
        )
        anim.add(gsap.to(this.closeButton, { duration: 0.15, opacity: 1 }))
      }
    )
  }

  set = name => dom => {
    this[name] = dom
  }

  componentDidMount() {
    this.closeHeight = this.container.getBoundingClientRect().height + 40
    if (new URLSearchParams(window.location.search).get('noinfo') === 'true') {
      requestAnimationFrame(this.close)
    }
  }

  render() {
    const title = css`
      font-size: 30px;
      font-weight: bold;
    `
    const instructions = css`
      font-size: 0.7rem;

      * {
        font-size: 0.7rem;
      }

      ul {
        padding: 0 0 5px 0;
      }
    `

    return (
      <Outer ref={this.set('outer')}>
        <Container ref={this.set('container')}>
          <CloseButton ref={this.set('closeButton')} onClick={this.close}>
            &times;
          </CloseButton>
          <div css={title}>
            This layout has a Zombie Infection! Can you fix it?
          </div>
          <div css={instructions}>
            <p>Using the Firefox Developer Tools:</p>
            <ul
              css={css`
                list-style-type: none;
              `}
            >
              <li
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                1. Select the{' '}
                <img
                  alt="selector-pic"
                  css={css`
                    width: 14ch;
                    margin-left: 1ch;
                    margin-right: 1ch;
                  `}
                  src="/fbz_challenge/selectorpic.png"
                />
                element(s)
              </li>
              <li>
                2. Figure out what's happening using the new{' '}
                <a
                  css={css`
                    color: #fff;
                    text-decoration: none;
                    border-bottom: 2px solid #fff;
                    &:hover {
                      color: #90319b;
                      border-color: #90319b;
                    }
                  `}
                  href="https://developer.mozilla.org/en-US/docs/Tools/Page_Inspector/How_to/Examine_Flexbox_layouts"
                >
                  Flexbox Inspector
                </a>
              </li>
              <li>
                3. Use Flexbox properties to move the zombie infections{' '}
                <Icon src="/fbz_challenge/zombiebox-icon.png" width="25" />
                into the blue quarantine boxes{' '}
                <Icon src="/fbz_challenge/clearbox-icon.png" width="25" />
              </li>
            </ul>
          </div>
          <FirefoxLogo onClick={this.open} usePointer={!this.state.isOpen} />
        </Container>
      </Outer>
    )
  }
}
