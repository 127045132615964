import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import theme from '../components/theme'

const Button = props => {
  const styles = css`
    display: inline-block;
    color: ${props.color};
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid ${props.color};
    padding: 0.25rem 1rem;
    margin-top: 1rem;
    &:hover {
      color: ${theme.color.pink};
      border-color: ${theme.color.pink};
    }

    &:active {
      color: ${theme.color.purple};
      border-color: ${theme.color.purple};
    }

    ${theme.font.small};
  `

  const internal = /^\/(?!\/)/.test(props.to)

  // Use gatsby-link for internal links, and <a> for others
  if (internal) {
    return (
      <Link css={styles} to={props.to}>
        {props.children}
      </Link>
    )
  }

  return (
    <a href={props.to} css={styles}>
      {props.children}
    </a>
  )
}

Button.defaultProps = {
  //color: '#a4a4a6',
  color: '#7d7d80',
}

export default Button
