/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import React from "react";

type Variant = "input" | undefined;

interface Props {
  enabled?: Boolean;
  processing?: Boolean;
  children?: any;
  onClick?: Function;
  to?: string;
  variant?: Variant;
}

const getPadding = (variant: Variant) => {
  if (variant === "input"){
    return "0.2rem 0.5rem";
  }
  return "0.8rem 0.5rem";
}

const getFontSize = (variant: Variant) => {
  if (variant === "input"){
    return "16px";
  }
  return "1.1rem";
}

/**
 * 
 */
export const SolidButton = (props: Props) => (
  <button css={theme => css`
    border: none;
    padding: ${getPadding(props.variant)};
    transition: background-color 0.2s ease-out, color 0.2s ease-out;
    outline: none;
    color: #fff;
    font-family: Inter, sans-serif;
    font-variation-settings: 'wght' 450;
    font-size: ${getFontSize(props.variant)};
    background: ${theme.color.secondary.mid};
    width: 100%;
    opacity: ${!props.enabled || props.processing ? 0.4 : 1 };
    cursor: ${props.enabled ? "pointer" : "default" };
    border-radius: 5px;

    &:hover {
      background: ${props.enabled ? theme.color.accent.mid: theme.color.secondary.mid};
    }

    &:active {
      background: ${theme.color.primary.dark};
      color: #fff;
    }
  `} onClick={(e) => {
    e.preventDefault();
    if (props.enabled !== false){
      props.onClick && props.onClick();
      
      if (props.to) {
        window.location.href = props.to;
      }
    }
  }}>{props.children}</button>
)








// not currently using any of this, but I do want a solid button

// const pulse = keyframes`
//   0% {
//     opacity: 1;
//     filter: drop-shadow(0px 0px 0px #fff);
//   }
//   15% {
//     opacity: .3;
//     filter: drop-shadow(-6px -6px 0px rgba(255, 255, 255, 1));
//   }
//   30% {
//     opacity: 1;
//     filter: drop-shadow(0px 0px 0px #fff);
//   }
  
// `;

// const ButtonStyle = styled.span`
//   display: flex;
//   user-select: none;
//   visibility: ${props => {
//     return props.hidden ? "hidden" : "visible";
//   }};
//   padding: ${props => {
//     return props.narrow ? "0 9px 0 0px" : "0 15px 0 18px";
//   }};
//   height: 35px;
//   font-size: 16px;
//   position: relative;
//   color: #c3ecf5;
//   border: 1px solid #c3ecf5;
//   background: #2e2e32;
//   cursor: ${props => {
//     return props.disabled ? "default" : "pointer";
//   }};
//   opacity: ${props => {
//     return props.disabled ? 0.3 : 1;
//   }};

//   ${props => {
//     if (props.callToAction && !props.disabled) {
//       return `
//         animation: ${pulse} 1.5s .5s ease-in infinite;
//       `;
//     }
//   }};

//   ${props => {
//     if (props.active) {
//       return `
//         color: #46ffff !important;
//         polygon {
//           stroke: #46ffff !important;
//         }
//         img {
//           filter: sepia() saturate(10000%) hue-rotate(140deg) !important;
//         }
//       `;
//     }
//   }};

//   &:active {
//     color: #46ffff !important;

//     polygon {
//       stroke: #46ffff !important;
//     }

//     img {
//       /* turn volume icon colors */
//       filter: sepia() saturate(10000%) hue-rotate(140deg) !important;
//     }
//   }

//   &:hover {
//     animation: none;

//     ${props => {
//       if (!props.disabled) {
//         return `color: #fff;
        
//             img {
//               /* turn volume icon white */
//               filter: saturate(0%);
//             }
        
//             polygon {
//               stroke: #fff;
//             }
        
//             svg {
//               filter: drop-shadow(-6px 5px 0px rgba(255, 255, 255, 0.3));
//             }`;
//       }
//     }};
//   }
// `;

// export default class Button extends React.Component {
//   onClick = () => {
//     if (!this.props.disabled && this.props.onClick) {
//       this.props.onClick();
//     }
//   };

//   render() {
//     return (
//       <ButtonStyle
//         disabled={this.props.disabled}
//         active={this.props.active}
//         narrow={this.props.narrow}
//         callToAction={this.props.callToAction}
//         onClick={this.onClick}
//         hidden={this.props.hidden}
//       >
//         <div style={{ alignSelf: "center", zIndex: 99 }}>
//           {this.props.children}
//         </div>
//       </ButtonStyle>
//     );
//   }
// }
