import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { gsap } from 'gsap'

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  height: 80px;
  z-index: 2;
`

const Content = styled.div`
  background: #eb4061;
  padding: 20px;
  font-size: 0.6rem;
  width: 480px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 3;
  justify-content: space-between;
`

const ZombieHead = styled.div`
  width: 80px;
  height: 100px;
  background-image: url('/fbz_challenge/ZombieHead.png');
  background-size: contain;
  align-self: flex-start;
  position: relative;
  bottom: 10px;
`

const Message = styled.div`
  font-weight: 500;
  color: #200f2e;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
`

const Title = () => {
  const imgStyle = css`
    width: 160px;
  `
  return <img css={imgStyle} alt="" src="/fbz_challenge/fbz_title_dark.png" />
}

const PlayNow = () => {
  const styles = css`
    height: 35px;
    border: 1px solid #200f2e;
    padding: 6px 20px;
    font-weight: 500;
    font-size: 90%;
    color: #200f2e;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: #a9c25f;
      border-color: #a9c25f;
    }
  `

  return (
    <div
      onClick={() => {
        window.location = 'https://flexboxzombies.com'
      }}
      css={styles}
    >
      <span>PLAY NOW</span>
    </div>
  )
}

export default class extends React.Component {
  die() {
    console.log('gonna die')
    return new Promise(onComplete => {
      let anim = gsap.timeline()

      anim.add(
        gsap.to(this.head, {
          duration: 0.5,
          top: -175,
          position: 'relative',
          easing: 'EaseOut',
          rotation: 45,
          onComplete: () => {
            this.head.style['z-index'] = 1
          },
        })
      )

      anim.add(
        gsap.to(this.head, {
          duration: 0.3,
          top: 300,
          alpha: 0,
          rotation: 90,
          easing: 'EaseOut',
          onComplete,
        })
      )

      anim.add(
        gsap.to(this.background, {
          duration: 0.3,
          alpha: 0,
        })
      )
    })
  }

  set = name => elem => {
    this[name] = elem
  }

  render() {
    return (
      <Container>
        <Content ref={this.set('background')}>
          <ZombieHead ref={this.set('head')} />
          <Message ref={this.set('message')}>
            <div>More challenges await you in...</div>
            <Title />
          </Message>
          <PlayNow />
        </Content>
      </Container>
    )
  }
}
